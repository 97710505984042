import React, { useEffect, useState } from "react";

const usePopup = () => {
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        const localStorageOpenPopupDate = localStorage.getItem("openPopupDate");

        if (localStorageOpenPopupDate) {
            const now = Date.now();
            const saved = JSON.parse(localStorageOpenPopupDate);

            var difference = now - saved;
            difference = difference / 60 / 60 / 1000;
            difference > 24 ? setOpenPopup(true) : setOpenPopup(false);
        } else {
            setOpenPopup(true);
        }
    }, []);

    const hidePopup = () =>
        localStorage.setItem("openPopupDate", Date.now().toString());

    return { openPopup, hidePopup };
};

export default usePopup;
