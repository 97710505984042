import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Guests from "../components/guests";

const GuestsPage = () => (
    <Layout>
        <SEO title="Festival Guests" />
        <Guests />
    </Layout>
);

export default GuestsPage;
