type Data = Array<{
    id: string;
    name: string;
    classification: string;
    filmography?: Array<string>;
    contents: Array<string>;
}>;

const data: Data = [
    {
        id: "douckmin",
        name: "Kim Douck Min",
        classification: "Guests",
        contents: [
            "Kim Douck Min has built a solid foundation in the film industry through his work as an assistant director on notable films such as Keys to the Heart and Hero. He is now stepping into the spotlight with his directorial debut, Dog Days. Collaborating with JK Film, known for beloved hits like Confidential Assignment and Keys to the Heart, Kim Douck Min is recognized for his ability to blend delightful laughter with heartwarming emotions, raising high expectations for Dog Days.\n\nIn Dog Days, Kim Douck Min weaves a warm and relatable narrative, featuring characters that resonate with everyday life. Through his unique cinematic interpretation and imagination, he tells a story of gradual growth, one warm tale at a time. The film promises to capture the hearts of audiences of all ages with its blend of laughter and emotions, bolstered by exceptional performances from top actors. This heartwarming film, filled with unparalleled warmth, is set to captivate theatres in the new year of 2024.\n\nDog Days will premiere at the Korean Film Festival in Australia, showcasing Kim Douck Min's directorial talent and storytelling prowess to an international audience.",
        ],
        filmography: ["DOG DAYS"],
    },
    {
        id: "damin",
        name: "Kim Da-min",
        classification: "Guests",
        contents: [
            "Born in 1993, Kim Da-min graduated from Korea Animation High School and pursued further studies in psychology and cultural anthropology at Yonsei University. She began her directing career with short films such as Slight Fever (2009) and Surface (2013). Her film Ungbi and Non-human Friends (2020) garnered attention at several film festivals, including the Bucheon International Fantastic Film Festival and the Boston Science Fiction Film Festival. In addition to her work in film, Kim Da-min wrote the original Netflix series Murder DIE-ary, which is set to launch in 2023.\n\nIn 2024, Kim Da-min's debut feature film, FAQ (막걸리가 알려줄거야), will premiere at the Korean Film Festival in Australia. The family comedy stars PARK Na-eun, PARK Hyo-ju, and KIM Hie-won. The film follows Dong-chun, an overworked elementary school student, who discovers a mysterious bottle of rice wine that begins to communicate with her in Morse code. Through this whimsical adventure, Dong-chun uncovers the secrets of the world and gains a new perspective on her life. FAQ promises to be a delightful and thought-provoking experience for audiences of all ages.",
        ],
        filmography: ["FAQ"],
    },
    {
        id: "yonggyun",
        name: "Kim Yong-gyun",
        classification: "Guests",
        contents: [
            "Born in 1969, Kim Yong-gyun graduated from the Department of Theater and Film at Hanyang University. He started his career as a cinematographer for the feature film Mother, Your Son (1991). He made his directorial debut with the romantic drama Wanee and Junha (2001) and continued to make a name for himself with the horror film The Red Shoes (2005), the historical drama The Sword with No Name (2009), and the thriller Killer Toon (2013). In 2023, he returned to the horror genre with Tastes of Horror.\n\nIn 2024, Kim Yong-gyun's latest film, Picnic (소풍), will have its Australian premiere at the Korean Film Festival in Australia. The film stars NA Moon-hee, KIM Yeong-ok, and PARK Keun-hyong, and it tells the story of eighty-year-old Eun Sim, who returns to her hometown after sixty years and reunites with her old friends, Geum Soon and Tae Ho. Picnic, a heartwarming drama, explores the blossoming of friendship and love in the later stages of life, reminiscent of a nostalgic trot song. This film marks Kim Yong-gyun's fifth feature film and continues to showcase his talent for capturing deep, emotional narratives.",
        ],
        filmography: ["PICNIC"],
    },
];

export default data;
