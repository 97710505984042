import React from "react";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageTitle from "../PageTitle";
import PageContainer from "../PageContainer";
import {
    Container,
    ContentsContainer,
    Title,
    TitleWrapper,
    Info,
    InfoBox,
    InfoNote,
    InfoContents,
    InfoContainer,
    InfoWarpper,
    TimeIcon,
    VideoIcon,
    TitleIcon,
    RegistrationIcon,
    DotIcon,
    ContentsWarpper,
    InfoContentsWrapper,
    InfoTitle,
    IconWarpper,
    AnchorButton,
    ImageWrapper,
    PersonWrapper,
    ContentsWrapper,
    Name,
    Contents,
    InlineLink,
} from "./styles";
import usePopup from "../../hooks/usePopup";
import useData from "./useData";
import Img from "gatsby-image";

const Guests = () => {
    const { openPopup } = usePopup();
    const data = useData();

    return (
        <PageContainer>
            <Breadcrumb>
                <BreadcrumbItem
                    Link="/festival-guests"
                    Text="Festival Guests"
                />
            </Breadcrumb>
            <PageTitle>Festival Guests</PageTitle>
            <Container>
                <InfoContainer>
                    <InfoBox>
                        <Info>2024 KOFFIA Guests</Info>
                    </InfoBox>
                </InfoContainer>
                <InfoContainer>
                    <InfoBox>
                        {data
                            .filter(item => item.classification == "Guests")
                            .map(item => (
                                <PersonWrapper key={item.name}>
                                    <ImageWrapper>
                                        <Img fluid={item.image} />
                                    </ImageWrapper>
                                    <ContentsWrapper>
                                        <Name>{item.name}</Name>
                                        {item.filmography && (
                                            <Contents>
                                                {item.filmography.map(filmo => {
                                                    if (filmo === "PICNIC") {
                                                        return (
                                                            <InlineLink to="/films/picnic">
                                                                PICNIC (2024)
                                                            </InlineLink>
                                                        );
                                                    } else if (
                                                        filmo === "DOG DAYS"
                                                    ) {
                                                        return (
                                                            <InlineLink to="/films/dog-days">
                                                                DOG DAYS (2024)
                                                            </InlineLink>
                                                        );
                                                    } else if (
                                                        filmo === "FAQ"
                                                    ) {
                                                        return (
                                                            <InlineLink to="/films/faq">
                                                                FAQ (2023)
                                                            </InlineLink>
                                                        );
                                                    } else {
                                                        return `, ${filmo}`;
                                                    }
                                                })}
                                            </Contents>
                                        )}
                                        {item.contents.map(content => (
                                            <ContentsWarpper key={content}>
                                                <Contents>{content}</Contents>
                                            </ContentsWarpper>
                                        ))}
                                    </ContentsWrapper>
                                </PersonWrapper>
                            ))}
                    </InfoBox>
                </InfoContainer>
            </Container>
        </PageContainer>
    );
};

export default Guests;
