import { useStaticQuery, graphql } from "gatsby";
import data from "./data";

const useData = () => {
    const images = useStaticQuery(graphql`
        fragment FluidImageForum on File {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        query {
            douckmin: file(relativePath: { eq: "forum/douckmin.jpg" }) {
                ...FluidImageForum
            }
            damin: file(relativePath: { eq: "forum/damin.jpg" }) {
                ...FluidImageForum
            }
            yonggyun: file(relativePath: { eq: "forum/yonggyun.jpg" }) {
                ...FluidImageForum
            }
        }
    `);

    return data.map(item => ({
        ...item,
        image: images[item.id].childImageSharp.fluid,
    }));
};

export default useData;
